
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';

@Component({
    components: { CustomSelect },
})
export default class PosDocumentFilter extends Vue {
    @inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Prop({
        required: false,
        type: Boolean,
        default: false,
    })
    disable!: boolean;

    @Prop({
        type: Array,
        default: null,
    })
    items!: Item[];

    get pos() {
        return this.documentFiltersService.storeState.settings.pos;
    }

    set pos(value) {
        this.documentFiltersService.savePos(value);
    }

    get posItems(): Item[] {
        if (this.items) return this.items;

        const { posRatesItems, posMarketItems } = this.documentFiltersService;
        const isRatesPage = this.$route.name ? this.$route.name.includes('rate') : false;
        return isRatesPage ? posRatesItems : posMarketItems;
    }
}
