
import moment from 'moment';
import { inject } from '@/inversify';
import { Mixins, Component } from 'vue-property-decorator';
import type Day from '@/modules/common/types/day.type';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import PromotionsOtelMixin from '@/modules/open-telemetry/mixins/promotions/promotions-page-otel-logs.mixin';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { PromotionGraphType } from '../models/promotions-settings.model';
import PromotionsGraphTooltip from './promotions-graph-tooltip.vue';

@Component({
    components: {
        CustomGraph,
        PromotionsGraphTooltip,
    },
})
export default class PromotionsGraph extends Mixins(PromotionsOtelMixin) {
    @inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    @inject(UserServiceS)
    private userService!: UserService;

    tooltipDay = 0;
    tooltipFocusElement: HTMLElement | null = null;
    hiddenGraphs: { [hotelId: string]: boolean } = {};

    get isLoading() {
        return this.promotionsService.isLoading;
    }

    get isNoData() {
        if (this.isLoading) return false;
        if (!this.promotionsService.data) return true;

        const { promotions } = this.promotionsService.data;

        return !Object.keys(promotions).length;
    }

    get isDealsMode() {
        return this.promotionsService.graphType === PromotionGraphType.DEALS;
    }

    private get currentHotelId() {
        return +this.$route.params.hotelId;
    }

    get days() {
        const { year, month } = this.documentFiltersService;
        const today = new Date();

        return this.documentFiltersService.days
            .map(day => {
                const date = new Date(year, month, day);
                const isWeekend = date.getDay() === 0 || date.getDay() === 6;
                const label = moment(date).format('ddd');
                const isCurrentDay = today.toDateString() === date.toDateString();

                return {
                    day,
                    label,
                    isCurrentDay,
                    isWeekend,
                };
            });
    }

    get graphAttrs() {
        const { days } = this.documentFiltersService;

        return {
            chartData: {
                labels: days.map(day => String(day)),
                datasets: this.daysData,
            },
            options: this.graphOptions,
            type: 'line',
        };
    }

    private get provider() {
        return this.promotionsService.provider;
    }

    get legendData() {
        const { data } = this.promotionsService;

        if (!data) return [];

        const hotelColors = this.hotelsService
            .getHotelsGraphColor();
        const hotels = [...this.documentFiltersService.competitors || []];

        if (this.userService.currentHotelId) {
            hotels.push(this.userService.currentHotelId);
        }

        return hotels.map(hotelId => ({
            hotelId,
            color: +hotelId === this.currentHotelId
                ? CURRENT_HOTEL_GRAPH_COLOR
                : hotelColors[hotelId],
            hotelName: this.hotelsService.getHotelName(hotelId),
        }));
    }

    private get daysData() {
        const { days } = this.documentFiltersService;
        const { data, graphType, programView } = this.promotionsService;
        const { provider } = this;

        if (!data || !provider) return [{}];

        const hotelColors = this.hotelsService
            .getHotelsGraphColor();

        const getPercent = (programList: { [program: string]: any }) => {
            const percentage = Object
                .values(programList)
                .find(program => program.percentage !== undefined);

            if (!percentage) return 0;

            return percentage.percentage;
        };

        const getActiveProgramsCount = (programList: { [program: string]: any }) => Object
            .values(programList)
            .filter(program => program.status && !('percentage' in program))
            .length;

        const getHotelColor = (hotelId: number) => {
            if (this.hiddenGraphs[hotelId]) return 'transparent';

            return +hotelId === this.currentHotelId
                ? CURRENT_HOTEL_GRAPH_COLOR
                : hotelColors[hotelId];
        };

        if (!data.hotels.length) {
            return [{}];
        }

        return data.hotels
            .filter(hotelId => this.currentHotelId === hotelId || this.documentFiltersService.competitors!.includes(hotelId))
            .map(hotelId => ({
                borderColor: getHotelColor(hotelId),
                pointRadius: this.hiddenGraphs[hotelId] ? 0 : undefined,

                data: days.map(day => {
                    const programList = this.promotionsService
                        .getPrograms(day, provider, hotelId);

                    if (!programList) return 0;

                    if (graphType === PromotionGraphType.DEALS) {
                        return getPercent(programList);
                    }

                    if (programView) {
                        return programList[programView] && programList[programView].status
                            ? 1
                            : 0;
                    }

                    return getActiveProgramsCount(programList);
                }),
            }));
    }

    private get graphOptions() {
        const { graphType, programView } = this.promotionsService;

        return {
            maintainAspectRatio: false,
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    cubicInterpolationMode: 'monotone',
                    lineTension: 0,
                },
                point: {
                    radius: 4,
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#ECF1F5',
                    },
                    ticks: {
                        display: false,
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    ticks: {
                        autoSkip: true,
                        padding: 10,
                        callback: (value: number) => {
                            if (graphType === PromotionGraphType.DEALS) {
                                return `${String(value).padStart(2, ' ')}%`;
                            }

                            if (programView && String(value).includes('.')) {
                                return '';
                            }

                            return `${value}`.padEnd(7, ' ');
                        },
                    },
                    offset: true,
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        };
    }

    public setTooltipDay(label: string) {
        this.tooltipDay = (label
            ? parseInt(label, 10)
            : null) as Day;
    }

    public setTooltipElement(el: HTMLElement) {
        this.tooltipFocusElement = el;
    }

    public toggleGraph(hotelId: number) {
        if (this.hiddenGraphs[hotelId]) {
            this.hiddenGraphs = {
                ...this.hiddenGraphs,
                [hotelId]: false,
            };
            return;
        }

        this.hiddenGraphs = {
            ...this.hiddenGraphs,
            [hotelId]: true,
        };
    }
}
