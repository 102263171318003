var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CustomTooltip',_vm._b({staticClass:"promotions-calendar",attrs:{"width":"277px","tooltip-class":[
        'promotions-calendar__container',
        _vm.isActive ? 'promotions-calendar__container--active' : '',
    ].join(' ')}},'CustomTooltip',_vm.attrs,false),[(_vm.isPercentage && _vm.isMainHotel)?_c('p',{staticClass:"promotions-calendar__hint",domProps:{"textContent":_vm._s(_vm.$tc('promotions.tooltip.comparedWithAvgComp'))}}):_vm._e(),_c('div',{staticClass:"promotions-calendar__weekdays"},_vm._l((_vm.days),function(char,index){return _c('div',{key:char + index,domProps:{"textContent":_vm._s(char)}})}),0),_c('div',{staticClass:"promotions-calendar__grid"},_vm._l((_vm.numberOfDays),function(cell){return _c('div',{key:'day-cell' + cell,class:{
                'promotions-calendar__cell': true,
                ['promotions-calendar__cell--status--' + _vm.getCellStatus(cell)]: true,
            },attrs:{"data-day":_vm.getDay(cell),"data-percent":_vm.getPercent(cell),"data-provider":_vm.dataset.provider,"data-program":_vm.dataset.program},on:{"mouseover":function($event){return _vm.emitTooltipElement($event, _vm.day)},"mouseout":_vm.emitCellOut}},[(!_vm.isMainHotel && !_vm.isPercentage && _vm.isCellActive(cell))?_c('svg',{staticClass:"promotions-calendar__v-symbol",attrs:{"width":"8","height":"6","viewBox":"0 0 8 6","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7.53689 0.0497934C7.629 0.0844733 7.71272 0.136224 7.78302 0.201951C7.91961 0.330192 7.99813 0.503562 7.99997 0.684835C8.00181 0.866109 7.92781 1.04083 7.79385 1.17149L3.86459 5.77911C3.79707 5.84721 3.71562 5.90184 3.62511 5.93977C3.53459 5.97769 3.43686 5.99813 3.33775 5.99985C3.23863 6.00157 3.14016 5.98455 3.04821 5.9498C2.95626 5.91505 2.87271 5.86328 2.80254 5.79758L0.198128 3.35342C0.0677305 3.22214 -0.00325933 3.0485 0.000115011 2.86909C0.00348935 2.68967 0.0809641 2.5185 0.216218 2.39161C0.351471 2.26473 0.533943 2.19205 0.72519 2.18888C0.916438 2.18572 1.10153 2.25231 1.24147 2.37464L3.30158 4.30818L6.72 0.222265C6.78739 0.153907 6.86879 0.0990008 6.95933 0.0608305C7.04986 0.0226601 7.14767 0.00200952 7.24691 0.000113134C7.34614 -0.00178325 7.44477 0.0151134 7.53689 0.0497934Z","fill":"#D9D9D9"}})]):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }