var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.isLoading)?_c('div',{staticClass:"promotions-actions"},[_c('div',{staticClass:"promotions-actions__left-col"},[_c('CustomSelect',{attrs:{"label":_vm.$tc('titles.compset'),"items":_vm.compsetItems},model:{value:(_vm.compsetId),callback:function ($$v) {_vm.compsetId=$$v},expression:"compsetId"}}),_c('PosDocumentFilter'),_c('LosDocumentFilter'),_c('CustomMultiSelect',{attrs:{"label":_vm.$tc('titles.competitors'),"items":_vm.competitorsItems,"emitValuesOnly":""},model:{value:(_vm.competitors),callback:function ($$v) {_vm.competitors=$$v},expression:"competitors"}}),(_vm.isGraphView || _vm.isAnalysisView)?[(!!_vm.providerItems.length)?_c('CustomSelect',{attrs:{"label":_vm.$tc('titles.provider'),"items":_vm.providerItems,"disabled":!_vm.providerItems.length},model:{value:(_vm.provider),callback:function ($$v) {_vm.provider=$$v},expression:"provider"}}):_vm._e(),_c('CustomSelect',{attrs:{"label":_vm.$tc('titles.type'),"items":_vm.graphTypeItems},model:{value:(_vm.graphType),callback:function ($$v) {_vm.graphType=$$v},expression:"graphType"}}),_c('CustomSelect',{attrs:{"label":_vm.$tc('titles.view'),"items":_vm.viewItems},model:{value:(_vm.programView),callback:function ($$v) {_vm.programView=$$v},expression:"programView"}})]:(_vm.isTableView)?[(!!_vm.providerItems.length)?_c('CustomSelect',{attrs:{"label":_vm.$tc('titles.provider'),"items":_vm.providerItems,"disabled":!_vm.providerItems.length},model:{value:(_vm.provider),callback:function ($$v) {_vm.provider=$$v},expression:"provider"}}):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"promotions-actions__right-col"},[_c('router-link',{class:{
                'btn btn_transparent btn_square btn_link': true,
                'btn_active': _vm.isMainView,
                'btn--disabled': _vm.isAnalysisView
            },attrs:{"to":_vm.mainRoute}},[_c('span',{staticClass:"icon-Apps-icon"})]),_c('router-link',{class:{
                'btn btn_transparent btn_square btn_link': true,
                'btn_active': _vm.isTableView,
                'btn--disabled': _vm.isAnalysisView
            },attrs:{"to":_vm.tableRoute}},[_c('span',{staticClass:"icon-CI_table-icon"})]),_c('router-link',{class:{
                'btn btn_transparent btn_square btn_link': true,
                'btn_active': _vm.isGraphView,
                'btn--disabled': _vm.isAnalysisView
            },attrs:{"to":_vm.graphRoute}},[_c('span',{staticClass:"icon-CI_Graph-icon"})])],1),_c('div',{staticClass:"promotions-actions__analysis-bar"},[_c('AnalysisBtn',{ref:"analysisButton"})],1)]):_c('div',{staticClass:"promotions-actions"},[_c('div',{staticClass:"promotions-action__left-col flex flex--gap"},[_c('span',{staticClass:"skeleton skeleton--gray",domProps:{"textContent":_vm._s(_vm.helperService.fill(4))}}),_c('span',{staticClass:"skeleton skeleton--gray",domProps:{"textContent":_vm._s(_vm.helperService.fill(4))}}),_c('span',{staticClass:"skeleton skeleton--gray",domProps:{"textContent":_vm._s(_vm.helperService.fill(1))}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }